<template>
  <b-card>
    <form-component
      :subcategories="subcategories"
      :delivery-method="deliveryMethod"
      :is-create-view="true"
      @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/delivery-methods/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create, subcategories } from '@/http/delivery-methods';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      deliveryMethod: {
        company_name: '',
        additional_info: '',
        price: 0,
        company_logo: null,
        cash_on_delivery: false,
        is_inpost_based: false,
        categories: [],
        is_special_delivery: false,
        is_primary: false,
      },
      subcategories: [],
    };
  },

  created() {
    subcategories().then((response) => {
      this.subcategories = response.data.data;
    });
  },

  methods: {
    async onSubmit(form) {
      const formData = new FormData();
      formData.append('company_name', form.company_name);
      formData.append('additional_info', form.additional_info);
      formData.append('price', form.price);
      formData.append('company_logo', form.company_logo.file, form.company_logo.fileName);
      formData.append('lang', form.lang);
      formData.append('cash_on_delivery', form.cash_on_delivery ? 1 : 0);
      formData.append('is_inpost_based', form.is_inpost_based ? 1 : 0);
      formData.append('is_special_delivery', form.is_special_delivery ? 1 : 0);
      formData.append('is_primary', form.is_primary ? 1 : 0);

      if (form.is_special_delivery) {
        for (const category of form.categories) {
          formData.append('categories[]', category.id);
        }
      }

      try {
        await create(formData);

        this.showSuccessNotification('Dane zapisane', 'Metoda dostawy została pomyślnie dodana.');
        this.$router.push({ name: 'delivery-methods-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania metody dostawy. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
